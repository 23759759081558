import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { Paper, Typography } from "@mui/material";

export const Chat = () => {
  const [text, setText] = useState([]);
  const chatBox = useRef();

  useEffect(() => {
    fetch("http://livestream24.org/getChat.php")
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.parse(data.text));
        setText(JSON.parse(data.text));
        setTimeout(() => {
          chatBox.current.scrollTop = chatBox.current.scrollHeight;
        }, 500);
      });
  }, [setText]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetch("http://livestream24.org/getChat.php")
        .then((response) => response.json())
        .then((data) => {
          console.log(JSON.parse(data.text));
          setText(JSON.parse(data.text));
        });
    }, 5000);
    return () => clearInterval(interval);
  }, [setText]);

  const messagesIn24 = text.filter((message) => {
    return moment.unix(message.time).isAfter(moment().subtract(24, "hours"));
  });

  return (
    <Paper
      elevation={0}
      id="chat"
      sx={{
        marginTop: {
          md: "5em",
          xs: "1em",
        },
      }}
      style={{
        padding: "1em",
        borderRadius: 0,
        textAlign: "left",
        border: 0,
        boxShadow: 0,
        overflowY: "scroll",
        height: "470px",
        scrollBehavior: "smooth",
      }}
      ref={chatBox}
    >
      {messagesIn24.map((message, index) => {
        return (
          <div key={`message-${index}`}>
            <Typography variant="body2">
              {`${moment.unix(message.time).format("HH:mm")} `}
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                display="inline"
              >
                {`${htmlDecode(message.name)}: `}
              </Typography>
              {htmlDecode(message.text)}
            </Typography>
          </div>
        );
      })}
    </Paper>
  );
};

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}
