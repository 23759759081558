import "./App.css";
import { Chat } from "./components/Chat";
import { Bild } from "./components/Bild";
import { Paper, Grid, Typography, Box } from "@mui/material";

function App() {
  return (
    <Grid container className="App">
      <Bild />
      <Grid item md={8} xs={12}>
        <Paper
          elevation={0}
          style={{ marginTop: "5em" }}
          sx={{
            height: {
              xs: "300px",
              md: "500px",
            },
          }}
        >
          <iframe
            src="https://g0.ipcamlive.com/player/player.php?alias=638758bac6f11&autoplay=1&mute=1&disablefullscreen=1&disablevideofit=0"
            width="90%"
            height="100%"
            frameBorder="0"
            allowFullscreen
          >
            {" "}
          </iframe>
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Chat />
      </Grid>
      <Grid
        item
        md={8}
        xs={12}
        style={{ textAlign: "left", marginBottom: "1em" }}
        sx={{ marginTop: { md: "1em", xs: "2em" } }}
      >
        <Box
          sx={{ width: { xs: "100%", md: "95%" }, paddingLeft: { xs: "1em" } }}
          style={{ marginRight: 0, marginLeft: "auto" }}
        >
          <Typography variant="body2">01. - 24. Dezember 2022, 24/7</Typography>

          <Typography variant="body2">
            Lurgensteinsteg - Plei&szlig;em&uuml;hlgraben, 04109 Leipzig
          </Typography>
          <Typography variant="body2">
            {`Part of / Ein Teil von `}
            <a href="https://www.heikehennig.de/festivals/ueber-bruecken/livestream24">
              &Uuml;ber Br&uuml;cken
            </a>
          </Typography>
          <Typography variant="body2">
            <a href="https://www.heikehennig.de/wp-content/uploads/2022/11/Livestream24.pdf">
              Download PDF
            </a>
          </Typography>
        </Box>
      </Grid>
      <Grid item md={4}></Grid>
    </Grid>
  );
}

export default App;
