import { Modal, Box, Button } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "100%", // theme.breakpoints.up('xs')
  },
  maxWidth: {
    xs: 400, // theme.breakpoints.up('xs')
    sm: 500, // theme.breakpoints.up('sm')
    md: 600, // theme.breakpoints.up('md')
    lg: 600, // theme.breakpoints.up('lg')
    xl: 600, // theme.breakpoints.up('xl')
  },
  maxHeight: "100%",
  bgcolor: "transparent",
  border: "0",
  boxShadow: 0,
  borderRadius: 0,
  margin: 0,
  p: 4,
  overflow: "scroll",
};

export const Bild = () => {
  const [isHidden, setIsHidden] = useState(true);
  const todayNumber = moment().format("D");
  useEffect(() => {
    setTimeout(() => setIsHidden(false), 5000);
  }, []);
  return (
    <Modal open={!isHidden} disableAutoFocus={true}>
      <Box sx={style}>
        <Button
          variant="text"
          onClick={() => setIsHidden(true)}
          style={{
            position: "absolute",
            top: "2em",
            right: "1em",
          }}
        >
          X
        </Button>
        <img
          src={`http://livestream24.org/blaetter/brucke.jpeg`}
          width="100%"
          height="100%"
        />
      </Box>
    </Modal>
  );
};
