import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

export const Send = ({ name }) => {
  const [message, setMessage] = useState("");
  const [newName, setName] = useState(name);

  const sendMessage = () => {
    fetch(`http://livestream24.org/send.php`, {
      method: "POST",
      body: JSON.stringify({ message: message, name: newName }),
      headers: {
        // "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log("response", response);
      setMessage("");
    });
  };
  return (
    <div style={{ padding: "1em" }}>
      <div>
        <TextField
          name="name"
          initialValue={name}
          onChange={(event) => setName(event.target.value)}
          value={newName}
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <TextField
          multiline
          rows={5}
          name="text"
          onChange={(event) => setMessage(event.target.value)}
          value={message}
        />
      </div>
      <div style={{ marginTop: "1em" }}>
        <Button variant="outlined" onClick={sendMessage}>
          Senden
        </Button>
      </div>
    </div>
  );
};
